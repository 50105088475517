:root {
  --primary: #63b4ce;
  --secondary: #afebff;
  --primary-hover: #f36232;
  --primary-dark: #217069;
  /* --rgba-primary-1: rgba(91, 207, 197, 0.1);
  --rgba-primary-2: rgba(91, 207, 197, 0.2);
  --rgba-primary-3: rgba(91, 207, 197, 0.3);
  --rgba-primary-4: rgba(91, 207, 197, 0.4);
  --rgba-primary-5: rgba(91, 207, 197, 0.5);
  --rgba-primary-6: rgba(91, 207, 197, 0.6);
  --rgba-primary-7: rgba(91, 207, 197, 0.7);
  --rgba-primary-8: rgba(91, 207, 197, 0.8);
  --rgba-primary-9: rgba(91, 207, 197, 0.9); */
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

.sidebar aside.ant-layout-sider.ant-layout-sider-dark {
  background: var(--primary) !important;
  border-radius: 15px;
  margin: 2px;
}
.sidebar .ant-menu {
  background-color: var(--primary);
  color: black;
}
/* .sidebar .ant-layout {
  width: 100% !important;
} */
.sidebar .ant-menu-submenu-title {
  color: #fff !important;
}
.sidebar .ant-menu .ant-menu-item-selected {
  background-color: #afebff !important;
  /* color: var(--bs-white) !important; */
}
.sidebar .ant-layout-header {
  padding-inline: 0;
}
.sidebar .ant-menu-item:hover {
  background-color: #afebff !important;
}

.sidebar li.ant-menu-item:active {
  background-color: var(--bs-white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:active {
  background-color: var(--primary) !important;
}
.sidebar .ant-menu-submenu .ant-menu-inline {
  background: var(--bs-white) !important;
  border-radius: 10px !important;
  margin: 0px 15px 0px 15px !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: black;
  color: white;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item-selected {
  background-color: var(--primary);
}
.ant-menu .ant-menu-sub {
  background-color: var(--bs-white) !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item:hover {
  background-color: var(--primary) !important;
}
.ant-layout-header {
  background-color: transparent !important;
}
.ant-layout .ant-layout-sider-trigger {
  background-color: var(--primary);
}
.ant-layout .ant-layout-sider-trigger:hover {
  background-color: var(--secondary);
}
.ant-picker-ok {
  height: fit-content;
  line-height: 1%;
  border-radius: 1cqb;
  color: white;
  background: rgb(36, 147, 252);
}
/* .ant-switch-handle{
  background: rgb(214, 61, 61);
} */
.ant-switch-inner {
  background: rgb(82, 224, 82);
}
/* .ant-switch-inner-unchecked{
  background: rgb(31, 43, 31);
}  */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 5px;
}
.ant-table-thead {
  position: relative;
  color: gray;
  font-weight: 500;
  text-align: start;
  background: whitesmoke;
  /* border-bottom: 1px solid #f0f0f0; */
  transition: background 0.2s ease;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-popconfirm-buttons button:nth-child(2) {
  background-color: red !important;
}
.sidebar .ant-menu-item {
  color: white !important;
}
/* Customize the color of selected Radio buttons */
.radio-group .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background-color: var(--primary);
  border-color: transparent;
}
.radio-group .ant-radio-wrapper.ant-radio-wrapper-checked::after {
  background-color: white;
}
.ant-switch-inner {
  background: var(--primary);
}
.ant-input-disabled {
  font-size: small !important;
  font-weight: 500 !important;
}
.radio-group
  .ant-radio-wrapper.ant-radio-wrapper-checked
  .ant-radio-inner::after {
  background-color: white;
}
.radio-group .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--bs-gray-300) !important;
}
.custom-radio-group .ant-radio-button-wrapper-checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: white !important;
}

.toggle {
  width: 70px !important;
}
.toggle .ant-switch-inner {
  background-color: var(--secondary) !important;
}
.toggle .ant-switch-inner .ant-switch-inner-checked {
  background-color: var(--secondary) !important;
}
.ant-input-disabled {
  font-size: medium;
  font-weight: 700;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
  float: right;
}
.ant-image-preview-img {
  width: 30%;
  height: 50%;
}
/* .ant-table-cell{
  background: rgba(255, 255, 0, 0.247);
} */
.customModal.ant-modal {
  width: 60% !important;
  top: 5%;
}
